<template>
    <div class="row d-md-flex">
        <div v-for="(publication, index) in publications" :key="index" class="col-12 col-sm-6 col-md-3 blog-item"
             @click="goToSection(publication)">
            <div class="blog-item">
                <div v-if="publication['blog_imagen']['slug']" class="img-thumb-blog">
                    <img
                        :src="`${publication['blog_imagen']['public_path']}?size=prev-${publication['blog_imagen']['slug']}`"
                        :alt="publication['blog_imagen']['alt']">
                </div>
                <div class="blog-cat" v-if="publication['blog_fuente_autor']">
                    {{ publication['blog_fuente_autor'] }}
                </div>
                <div class="blog-title" v-if="publication['title']">
                    <h4 v-html="publication['title']"></h4>
                </div>
                <div class="blog-date" v-if="publication['blog_fecha_publicacion']">
                    {{ publication['blog_fecha_publicacion'] }}
                </div>
                <div class="blog-txt" v-if="publication['blog_fuente_descripcion']"
                     v-html="publication['blog_fuente_descripcion']">
                </div>
                <div class="blog-read-more text-right">
                    <p @click="goToSection(publication)">
                        Leer más
                        <img v-img-prefix :src="`/public-assets/arrow-right-n`" class="img-fluid" alt="arrow-right-n"
                             width="16"
                             height="13"/>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        publications: Array
    },
    data() {
        return {
            isLoading: true,
        }
    },
    methods: {
        goToSection(data) {
            trackClick('search-blog', data.slug)
            this.$emit('saveUserSearch', data.slug)
            window.location.href = `/blog/${data.slug}`;
        },
    },
}
</script>

<style lang="css">
.blog-item {
    cursor: pointer;
}
</style>