
<div class="container pb-5">
    <div class="col-12 text-center"
         v-if="products.length === 0 && promotions.length === 0 && publications.length === 0 && filterSections.length === 0 && !isLoading">
        <h3>No se encontraron resultados para "{{ searchWords }}"</h3>
    </div>
    <div class="col-12 text-center " v-if="isLoading">
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="row">
        <div class="col-12  mb-4" v-if="!isLoading && filterSections.length > 0">
            <h3 class="subtitle mb-4">"{{ searchWords }}" en Secciones</h3>
            <Sections :sections="filterSections" :show-scroll="false" @saveUserSearch="saveUserSearch"/>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mb-4" v-if="!isLoading && products.length > 0">
            <h3 class="subtitle mb-4">"{{ searchWords }}" en productos</h3>
            <Products :products="products" @saveUserSearch="saveUserSearch"/>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mb-4" v-if="!isLoading && promotions.length > 0">
            <h3 class="subtitle mb-4">"{{ searchWords }}" en promociones</h3>
            <Promotions class="mb-5" :promotions="promotions" @saveUserSearch="saveUserSearch"/>
        </div>
    </div>
    <div class="row">
        <div class="col-12 " v-if="!isLoading && publications.length > 0">
            <h3 class="subtitle mb-4">"{{ searchWords }}" en Blog</h3>
            <Notes :publications="publications" @saveUserSearch="saveUserSearch"/>
        </div>
    </div>
</div>
