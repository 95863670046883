<template>
    <div class="container pb-5">
        <div class="col-12 text-center"
             v-if="products.length === 0 && promotions.length === 0 && publications.length === 0 && filterSections.length === 0 && !isLoading">
            <h3>No se encontraron resultados para "{{ searchWords }}"</h3>
        </div>
        <div class="col-12 text-center " v-if="isLoading">
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12  mb-4" v-if="!isLoading && filterSections.length > 0">
                <h3 class="subtitle mb-4">"{{ searchWords }}" en Secciones</h3>
                <Sections :sections="filterSections" :show-scroll="false" @saveUserSearch="saveUserSearch"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-4" v-if="!isLoading && products.length > 0">
                <h3 class="subtitle mb-4">"{{ searchWords }}" en productos</h3>
                <Products :products="products" @saveUserSearch="saveUserSearch"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-4" v-if="!isLoading && promotions.length > 0">
                <h3 class="subtitle mb-4">"{{ searchWords }}" en promociones</h3>
                <Promotions class="mb-5" :promotions="promotions" @saveUserSearch="saveUserSearch"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 " v-if="!isLoading && publications.length > 0">
                <h3 class="subtitle mb-4">"{{ searchWords }}" en Blog</h3>
                <Notes :publications="publications" @saveUserSearch="saveUserSearch"/>
            </div>
        </div>
    </div>
</template>

<script>
import Products from '../templates/Products.vue';
import Promotions from './Promotions.vue';
import Notes from './Notes.vue';
import Sections from "./Sections.vue";
import parseText from "@/helpers/transformHtmlText.js";
import {closest, distance} from 'fastest-levenshtein/mod';

export default {
    components: {
        Sections, Products, Promotions, Notes
    },
    props: {
        sections: {type: Array, default: () => []}
    },
    inject: ['userSearch'],
    data() {
        return {
            stories: [],
            countRequest: 0,
            searchWords: null,
            searchKeyWords: null,
            products: [],
            publications: [],
            promotions: [],
            filterSections: [],
            dictionary: [],
            isLoading: false,
        }
    },
    created() {
        this.$bus.$on('emit-search', (payload) => {
            this.products = []
            this.promotions = []
            this.publications = []
            this.filterSections = []
            this.searchWords = payload
            if (this.stories.length > 0) {
                let filteredStories = this.getMatchingResults(this.stories)
                this.segmentData(filteredStories)
                this.saveSearch()
            } else {
                if (this.countRequest === 0) {
                    this.searchItems()
                }
            }
        })
    },
    methods: {
        searchItems() {
            this.countRequest++;
            this.isLoading = true;
            this.$axios.get('/api/story-versions')
                .then(res => {
                    let apiStories = this.cleanStories(res.data.data)
                    this.stories = apiStories.concat(this.sections)
                    this.dictionary = [...this.createDictionary(this.stories)] //parse set in array
                    let filteredStories = this.getMatchingResults(this.stories)
                    this.segmentData(filteredStories)
                    this.saveSearch()
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        cleanStories(data) {
            data.forEach((story) => {
                if (story.dinero_al_instante) {
                    let results = story.dinero_al_instante.toLowerCase().match(/([^><]+)(?=<|$)/g)
                    story.dinero_al_instante = parseText(results.join(','))
                }
                if (story.tags) {
                    story.tags = story.tags.map(tag => tag.tag).join(', ').toLowerCase()
                }
            })
            return data
        },
        getMatchingResults(data) {
            let search = this.autocorrector(this.searchWords)
            return data.filter(item => item.title.toLowerCase().includes(search) || this.inSectionKeyWords(item, search) || this.inProductsDescription(item, search) || this.inStoryTag(item, search))
        },
        inSectionKeyWords(item, search) {
            if (item.descripcion && item.descripcion !== '')
                return item.descripcion.includes(search)
            return false
        },
        inProductsDescription(item, search) {
            if (item?.dinero_al_instante && item.dinero_al_instante !== '')
                return item.dinero_al_instante.includes(search)
            return false
        },
        inStoryTag(item, search) {
            if (item?.tags && item.tags !== '')
                return item.tags.includes(search)
            return false
        },
        segmentData(data) {
            const addItemHandler = {
                '1': this.promotions,
                '2': this.products,
                '3': this.publications
            }
            data.forEach(item => {
                let template_id = item.story_template_id
                let list = template_id ? addItemHandler[template_id.toString()] : this.filterSections
                if (list)
                    list.push(item)
            })
        },
        autocorrector(text) {
            let words = text.toLowerCase().split(/[\s,.¡!¿?]+/)
            return words.map(word => this.correctWord(word)).map(word => word.range <= 2 ? word.closeWord : word.word).join(' ')
        },
        correctWord(word) {
            if (word.length <= 2) return {word, closeWord: word, range: 0}
            let closeWord = closest(word, this.dictionary)
            let range = distance(word, closeWord)
            return {word, closeWord, range}
        },
        createDictionary(stories) {
            let titles = stories.filter(story => story?.title).flatMap(story => this.getWordsText(story.title))
            let productsDescription = stories.filter(story => story?.dinero_al_instante).flatMap(story => this.getWordsText(story.dinero_al_instante))
            let tags = stories.filter(story => story?.tags).flatMap(story => this.getWordsText(story.tags))
            let sectionKeyWords = stories.filter(story => story?.descripcion).flatMap(story => this.getWordsText(story.descripcion))
            let words = [].concat(titles, productsDescription, tags, sectionKeyWords)
            return new Set(words)
        },
        getWordsText(text) {
            let words = parseText(text).toLowerCase()
            words = words.split(/[\s,.¡!¿?]+/).filter(word => word.length >= 3)
            return words
        },
        async saveSearch() {
            let search = this.autocorrector(this.searchWords)
            this.$emit('saveSearch', this.removeDiacritics(search))
        },
        removeDiacritics(word) {
            //kepp  ͂ for letter ñ
            return word.normalize('NFD').replace(/[\u0300-\u0302\u0304-\u0341\u0343-\u036f]/g, '')
        },
        saveUserSearch(cta) {
            let data = {
                userSearch: this.userSearch,
                cta
            }
            this.$axios.post('/api/add_cta_user_search', {data})
        },
    }
}
</script>
