
<div id="search-container" class="search">
    <div class="navbar-search">
        <div class="row d-flex justify-content-around align-items-center flex-nowrap mx-auto h-100"
             style="max-width: 1400px">
            <form class="input-container col-9 col-md-11 mt-3 mb-3">
                <div @click="closeModal()">
                    <i class="fa-light fa-arrow-left fa-xl"></i>
                </div>
                <input ref="input_search" v-model="wordsSearch" autofocus @keyup.enter="searchItems()"
                       class="form-control px-5" id="search-input"
                       type="text" placeholder="¿Qué estás buscando?" v-on:keypress="allowedKeys($event)">
                <div @click="clearSearch()">
                    <i class="fa-duotone fa-x fa-xl"></i>
                </div>
            </form>
            <div>
                <img v-img-prefix class="img-fluid cursor-pointer" :src="`/public-assets/finder`" alt="finder"
                     @click="searchItems()">
            </div>
        </div>
    </div>
    <div style="width: 100%; background: white; height: 100vh">
        <div class="searches-container">
            <div class="header">
                <p>Búsquedas en tendencia:
                    <span v-for="(search, index) in showTrendings" class="keyword" @click="searchKeyWord(search)">
                        {{ trendingText(search, index) }}
                    </span>
                </p>
            </div>
            <div class="main row">
                <div class="col-12 col-md-2 mb-5">
                    <p>Búsquedas Recientes</p>
                    <div v-for="search in recent" class="keyword" @click="searchKeyWord(search)">
                        {{ filterTextLength(search) }}
                    </div>
                </div>
                <div class="content col-12 col-md-10">
                    <div v-show="!isShowResults">
                        <p>Secciones del sitio</p>
                        <Sections :sections="sections"/>
                    </div>
                    <div class="searches-container" v-show="isShowResults">
                        <Search :sections="sections" @saveSearch="saveSearch"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
