<template>
    <div class="row">
        <div v-for="(promo, index) in promotionsDataOrdened" :key="index" @click="goToPromotion(promo)"
             class="col-12 col-sm-4 col-md-4 promo-item text-center">
            <img v-if="promo.promocion_banner_image"
                 :src="`${promo.promocion_banner_image.public_path}?size=promo&ts=${promo.promocion_banner_image.updated_at}`"
                 :alt="promo.promocion_banner_image.alt" class="img-fluid" loading="lazy">
            <a class="promo-title">{{ promo.title }}</a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        promotions: Array
    },
    methods: {
        goToPromotion(data) {
            let url = data.cta !== null ? data.cta : `/promociones/${data.id}`;
            trackClick('search-promotion', url)
            this.$emit('saveUserSearch', url)
            window.location.href = url
        }
    },
    computed: {
        promotionsDataOrdened() {
            return this.promotions.sort((a, b) => a.id - b.id).reverse();
        }
    }
}
</script>