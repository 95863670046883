<template>
    <div id="search-container" class="search">
        <div class="navbar-search">
            <div class="row d-flex justify-content-around align-items-center flex-nowrap mx-auto h-100"
                 style="max-width: 1400px">
                <form class="input-container col-9 col-md-11 mt-3 mb-3">
                    <div @click="closeModal()">
                        <i class="fa-light fa-arrow-left fa-xl"></i>
                    </div>
                    <input ref="input_search" v-model="wordsSearch" autofocus @keyup.enter="searchItems()"
                           class="form-control px-5" id="search-input"
                           type="text" placeholder="¿Qué estás buscando?" v-on:keypress="allowedKeys($event)">
                    <div @click="clearSearch()">
                        <i class="fa-duotone fa-x fa-xl"></i>
                    </div>
                </form>
                <div>
                    <img v-img-prefix class="img-fluid cursor-pointer" :src="`/public-assets/finder`" alt="finder"
                         @click="searchItems()">
                </div>
            </div>
        </div>
        <div style="width: 100%; background: white; height: 100vh">
            <div class="searches-container">
                <div class="header">
                    <p>Búsquedas en tendencia:
                        <span v-for="(search, index) in showTrendings" class="keyword" @click="searchKeyWord(search)">
                            {{ trendingText(search, index) }}
                        </span>
                    </p>
                </div>
                <div class="main row">
                    <div class="col-12 col-md-2 mb-5">
                        <p>Búsquedas Recientes</p>
                        <div v-for="search in recent" class="keyword" @click="searchKeyWord(search)">
                            {{ filterTextLength(search) }}
                        </div>
                    </div>
                    <div class="content col-12 col-md-10">
                        <div v-show="!isShowResults">
                            <p>Secciones del sitio</p>
                            <Sections :sections="sections"/>
                        </div>
                        <div class="searches-container" v-show="isShowResults">
                            <Search :sections="sections" @saveSearch="saveSearch"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Search from './Search.vue'
import Sections from "./Sections.vue";
import parseText from "@/helpers/transformHtmlText.js";
import {computed} from "vue";

export default {
    components: {
        Search, Sections
    },
    props: {
        sections: Array,
        initialSearch: {type: String, required: false},
        trending: Array,
    },
    data() {
        return {
            isShowResults: false,
            wordsSearch: this.initialSearch,
            recent: [],
            coordinates: [],
            userSearch: null,
        }
    },
    provide() {
        return {
            userSearch: computed(() => this.userSearch)
        }
    },
    created() {
        // transform descripcion to array
        this.sections.forEach((section) => {
            if (section.descripcion && section.descripcion !== '')
                section.descripcion = parseText(section.descripcion.toLowerCase())
        })
    },
    mounted() {
        if (this.initialSearch) this.searchItems()
        this.getUserSearch()

        let body = document.getElementsByTagName('body')[0]
        body.style.overflowY = 'hidden'

        navigator.geolocation.getCurrentPosition((pos) => {
            this.coordinates = [pos.coords.longitude, pos.coords.latitude]
        })
    },
    methods: {
        searchItems() {
            if (this.wordsSearch != null && this.wordsSearch !== '') {
                this.$bus.$emit('emit-search', this.wordsSearch)
                this.isShowResults = true
                trackClick('search_cta', this.wordsSearch)
            } else {
                this.isShowResults = false
            }

        },
        showSection() {
            this.wordsSearch = ''
            this.isShowResults = false
        },
        allowedKeys(e) {
            //Validate if text is only number or text
            let char = String.fromCharCode(e.keyCode)
            if (/^[A-Za-z0-9, ñ]+$/.test(char)) return true
            else e.preventDefault()
        },
        searchKeyWord(word) {
            this.wordsSearch = word
            this.searchItems()
        },
        trendingText(search, index) {
            let coma = index === (this.trending.length - 1) ? '' : ','
            return search + coma
        },
        async getUserSearch() {
            let localSearches = localStorage.getItem('pp-recent-searches') || ""
            this.recent = JSON.parse(localSearches)
        },
        saveSearch(search) {
            this.addSearchInLocal(this.wordsSearch)
            this.saveCustomerSearch(search)
            this.saveUserSearch(search)
        },
        async addSearchInLocal(word) {
            word = word.toLowerCase()
            if (word.length <= 2 || this.recent.indexOf(word) !== -1)
                return

            this.recent.unshift(word)
            this.recent = this.recent.slice(0, 5)
            localStorage.setItem('pp-recent-searches', JSON.stringify(this.recent))
        },
        async saveCustomerSearch(search) {
            if (search.length <= 2)
                return

            this.$axios.post('/api/customer_search', {search}).catch(error => {
                console.error(error)
            })
        },
        async saveUserSearch(search) {
            let data = {
                coordinates: this.coordinates,
                written: this.wordsSearch,
                search,
            }
            this.$axios.post('/api/user_search', {data})
                .then(response => {
                    this.userSearch = response.data.data
                })
        },
        closeModal() {
            this.$bus.$emit('close-search')
        },
        clearSearch() {
            this.wordsSearch = ''
            this.isShowResults = false
        },
        filterTextLength(str) {
            if (str.length <= 15) return str
            return str.substring(0, 15) + '...'
        },
    },
    computed: {
        showTrendings() {
            return this.trending.map(word => parseText(word))
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar-search {
    height: 65px;
}

.search {
    position: fixed;
    top: 65px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 12000;
    overflow-y: hidden;
}

.searches-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: white;
    padding: 1.1rem .5rem;
    max-width: 1400px;
    margin: auto;

    p {
        color: #888888;
    }
}

.header {
    height: 50px;
}

.main {
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    padding-bottom: 10rem;

    p {
        font-size: 1.1rem;
    }
}

.keyword {
    color: #133C92;
    cursor: pointer;
    width: fit-content;
}

.input-container {
    position: relative;
}

.input-container div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-container div:first-child {
    left: 30px;
}

.input-container div:last-child {
    right: 30px;
}

@media (max-width: 800px) {
    .search {
        top: 0;
    }

    .icon-container {
        display: none;
    }
}

@media (max-width: 570px) {
    .form-control {
        font-size: .8rem;
    }
}
</style>